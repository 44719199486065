import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {NgModule, Injector} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {ErrorInterceptor} from '@shared/error.interceptor';

import {RouterModule} from '@angular/router';
import {routes} from './app.routes';
import {CoreModule} from '@shared/core.module';
import {AppComponent} from './app.component';
import {AppInsightsInterceptor} from '@shared/app-insights.interceptor';
import {AppInsightsService} from '@shared/services/app-insights.service';
import {CanDeactivateGuard} from '@shared/guards/can-deactivate.guard';
import {AuthorizationGuard} from '@shared/authorization.guard';
import {BasicSharedModule} from '@shared/basic-shared.module';
import {HttpClientModule} from '@angular/common/http';
import {LocatorService} from '@shared/services/locator.service';
import {NextGenMFAErrorInterceptor} from '@shared/nextgen-mfa-error.interceptor';
import {NextGenForcePasswordChangeErrorInterceptor} from '@shared/nextgen-force-password-change-error.interceptor';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ToastrModule} from "ngx-toastr";
import {setLicenseKey} from "@grapecity/wijmo";
import { provideNgxMask } from 'ngx-mask';
import { ModalModule } from "ngx-bootstrap/modal";
import { InsightsModule } from './app-insights.module';
import { NotificationComponent } from "shared/components/notification.component";

setLicenseKey('nextgen.bakerhillsolutions.net|nextgen.beta.bakerhillsolutions.net|localhost|nextgen.clientexp2.bakerhillsolutions.net|nextgen.clientexp.bakerhillsolutions.net|nextgen.earlyexp.bakerhillsolutions.net|hotfix.nextgen.bakerhilldev2.net|develop.nextgen.bakerhilldev2.net|release.nextgen.bakerhilldev2.net,539183864548242#B0Sdx36csxWaoJXZrFmYuEGdlJmLuV6Z4hXZuxCdl9mLz96bpRXds36csxWaoJXZrFmYu8WZnRHel9mI0IyctRkIsICcy36QgwGbphEIyV6ahJkI0ISYONkIsIiM4IDO4UDN6gzM8ETOzUjI0ICZJJCL355W0IyZsZmIsISM6RjMwIjI0IiclZnIsU6csFmZ0IiczRmI1pjIs9WQisnOiQkIsISP3c7cBtUb7o4a05kTzc6LU3UbMp7a5cmZqdVbM3iZMNkd9pFSHlFcpVkc82UcKFkQKpENyEGTTRUUBpVamNFTMVXO4MDcptSSaR6K834L82Ub8ATe4ZWRy26cD36U9UUasV6dOhXdrhjduNmUzdVRLpHbzAnRL9mQUpmQ8k4Yll5ZjFHVsZUe7tEdLh6Mu5EONhkMNVjeZJ6ZrVlUBFTRWRFaORUODF5KTlTZDNTZwMlSiRzTiRVOudnTMBFS0x4Q4oHTlZnVZpGerglc9dEMkZUeEVWSVJFavonQRhVSyQzTCZ7TXB7MaRDbJp5UmBnM8M5KYBlQ6xEUlJlSntSRHdlV8sSWQdnRvJVOulTSkF6QiRkSz8GUxUUS7ZnTuhTavEESrlURhlGV4RTVytiZRlHW9pHWH5ETJVUdEBXcwolR5cjZzYUUhZ6KnhGeN3UYwNmTx5GdVJiOiMlIsISOzkTRDFUO6IiOigkIsYDO8czM4gTO0IicfJye#4Xfd5nIJBjMSJiOiMkIsIibvl6cuVGd8VEIgQXZlh6U8VGbGBybtpWaXJiOi8kI1xSfiUTSOFlI0IyQiwiIu3Waz9WZ4hXRgAicldXZpZFdy3GclJFIv5mapdlI0IiTisHL3JyS7gDSiojIDJCLi86bpNnblRHeFBCI73mUpRHb55EIv5mapdlI0IiTisHL3JCNGZDRiojIDJCLi86bpNnblRHeFBCIQFETPBCIv5mapdlI0IiTisHL3JyMDBjQiojIDJCLiUmcvNEIv5mapdlI0IiTisHL3JSV8cTQiojIDJCLi86bpNnblRHeFBCI4JXYoNEbhl6YuFmbpZEIv5mapdlI0IiTis7W0ICZyBlIsIyNyIzM7ADI8EjNwQjMwIjI0ICdyNkIsICdl9mLyYXZkxGbphmcltWYi9ibldGd8VmbuU6chVGblJHL4VmbuIjdlRGbslGayV6ahJmLuV6Z4hXZu9CcvxWZ6VGZsQXZu9iM6VGZsxWaoJXZrFmYu8WZnRHel9mL8lmZ43GasQXZu9ycu3Wa4VHbvNHbslGayV6ahJmLwhXZ9xmchVmLuV6Z4hXZuxCdl9mLz96bpRXds36csxWaoJXZrFmYuAHelRnbllGbj9ibldGd8VmbsQXZu9ycu3Wa4VHbvNHbslGayV6ahJmLyAHelRnbllGbj9ibldGd8VmbsQ7cvhGbhN6bsxCdl9mLz9Pb5R');
@NgModule({
    imports: [
        BrowserModule,
        CoreModule,
        HttpClientModule,
        BasicSharedModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        RouterModule.forRoot(routes),
        ModalModule.forRoot(),
        InsightsModule,
        NotificationComponent
    ],
    declarations: [
        AppComponent,
    ],
    providers: [
        CanDeactivateGuard,
        AuthorizationGuard,
        AppInsightsService,
        { provide: HTTP_INTERCEPTORS, useClass: AppInsightsInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: NextGenMFAErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: NextGenForcePasswordChangeErrorInterceptor, multi: true },
        provideNgxMask()
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(private injector: Injector) {    // Wire up global Service Locator.
        LocatorService.injector = this.injector;
    }
}
